import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { Tooltip } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { BackendApis } from "src/api";

import { useNaivgateAbsolute, useNumberFormat } from "src/hooks";

import deletess from "src/assets/overview/delete.svg";
import first from "src/assets/overview/first.svg";
import five from "src/assets/overview/five.svg";
import four from "src/assets/overview/four.svg";
import secound from "src/assets/overview/secound.svg";
import six from "src/assets/overview/six.svg";
import third from "src/assets/overview/third.svg";
import { ProjectResponseDB } from "src/db-types/project";
import { ProductPopupProps } from "src/popups";
import rolechecker from "src/shared/RoleChecker/rolechecker";
import { getNumber } from "src/utils";

import { useProject } from "./hooks";
import { MainContainer } from "./ProjectCard.styles";

import { AppActions, PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import { openPopup } from "src/redux/popups/action/action.creators";
import { ProjectComponentsKeys } from "src/redux/project/types/components";
import { UserSelectors } from "src/redux/selectors";
import { getUser } from "src/redux/user/selectors";


interface IUserOverview {
  project: ProjectResponseDB;
  savedProjectsEnabled: boolean;
  onDelete: (address: string, id: string) => void;
  savedProject?: boolean;
  setfilteredData?: any;
}
export const ProjectCard: React.FC<IUserOverview> = (props: IUserOverview) => {
  const { project: projectDB, savedProjectsEnabled, onDelete, savedProject = true, setfilteredData } = props;
  const [query] = useSearchParams();
  const user = useSelector(getUser);
  const { t } = useTranslation();
  const navigate = useNaivgateAbsolute();
  const dispatch = useDispatch();
  const installerPartnerSlug = query.get("partner");
  const installerDetails = useSelector(UserSelectors?.getInstaller);

  const [shareBtnHover, setShareBtnHover] = useState<any>();
  const [trackId, setTrackId] = useState<any>(null);
  const [visibleShare, setOpenShareTray] = useState(false);
  const [lastOpenedPopup, setLastOpenedPopup] = useState<ProjectComponentsKeys>("waterHeating");

  const openProductHandler = (type: any) => {
    // todo
  };

  const {
    project,
    after20Years,
    solarPanelDetails: { systemCapacity, panelCapacity },
    filteredProducts,
    actions: {
      updateSolarPanelPref,
      updateBatteryPref,
      updateHeatpumpPref,
      updateInverterPref,
      updateWallboxPref,
      addBattery,
      addHeatpump,
      addWallbox,
      deleteBattery,
      deleteHeatpump,
      deleteWallbox,
    },
  } = useProject(projectDB);
  const { formatNumber } = useNumberFormat();
  const selectButtonHandler = () => {
    dispatch(
      AppActions.updateAppState({
        actions: {
          shouldLoadUniqueProjects: true,
        },
      }),
    );
    if (!installerPartnerSlug) {
      navigate(`/application/saved-${projectDB.shortId}/${projectDB.version}`);
    } else {
      navigate(
        `/application/saved-${projectDB.shortId}/${projectDB.version}?partner=${installerPartnerSlug}`,
      );
    }
  };

  const openSolarPanelPopup = () => {
    setLastOpenedPopup("solarPanel");
    const data: ProductPopupProps = {
      type: "solarPanel",
      standalone: {
        componentCosts: project.financialDetails.costs.componentCosts,
        filteredProducts: filteredProducts.solarPanels,
        productState: project.components.solarPanel,
        onPreferenceChange: (filters) => {
          updateSolarPanelPref(filters);
        },
      },
    };
    dispatch(PopupActions.openPopup(POPUP_KEYS.product, data));
  };

  const openInverterPopup = () => {
    setLastOpenedPopup("inverter");
    const data: ProductPopupProps = {
      type: "inverter",
      standalone: {
        componentCosts: project.financialDetails.costs.componentCosts,
        filteredProducts: filteredProducts.inverters,
        productState: project.components.inverter,
        onPreferenceChange: (filters) => {
          updateInverterPref(filters);
        },
        isBatteryAdded: project.components.battery.quantity > 0,
      },
    };
    dispatch(PopupActions.openPopup(POPUP_KEYS.product, data));
  };
  const openBatteryPopup = () => {
    setLastOpenedPopup("battery");
    const data: ProductPopupProps = {
      type: "battery",
      standalone: {
        componentCosts: project.financialDetails.costs.componentCosts,
        filteredProducts: filteredProducts.batteries,
        productState: project.components.battery,
        onPreferenceChange: (filters) => {
          updateBatteryPref(filters);
        },
      },
    };
    dispatch(PopupActions.openPopup(POPUP_KEYS.product, data));
  };

  const openHeatpumpPopup = () => {
    setLastOpenedPopup("heatpump");
    const data: ProductPopupProps = {
      type: "heatpump",
      standalone: {
        componentCosts: project.financialDetails.costs.componentCosts,
        filteredProducts: filteredProducts.heatpumps,
        productState: project.components.heatpump,
        onPreferenceChange: (filters) => {
          updateHeatpumpPref(filters);
        },
      },
    };
    dispatch(PopupActions.openPopup(POPUP_KEYS.product, data));
  };

  const openWallboxPopup = () => {
    setLastOpenedPopup("wallbox");
    const data: ProductPopupProps = {
      type: "wallbox",
      standalone: {
        componentCosts: project.financialDetails.costs.componentCosts,
        filteredProducts: filteredProducts.wallboxes,
        productState: project.components.wallbox,
        onPreferenceChange: (filters) => {
          updateWallboxPref(filters);
        },
      },
    };
    dispatch(PopupActions.openPopup(POPUP_KEYS.product, data));
  };

  useEffect(() => {
    if (lastOpenedPopup === "solarPanel") {
      const data: ProductPopupProps = {
        type: "solarPanel",
        standalone: {
          componentCosts: project.financialDetails.costs.componentCosts,
          filteredProducts: filteredProducts.solarPanels,
          productState: project.components.solarPanel,
          onPreferenceChange: (filters) => {
            updateSolarPanelPref(filters);
          },
        },
      };
      dispatch(PopupActions.updateData(POPUP_KEYS.product, data));
    }

    if (lastOpenedPopup === "battery") {
      const data: ProductPopupProps = {
        type: "battery",
        standalone: {
          componentCosts: project.financialDetails.costs.componentCosts,
          filteredProducts: filteredProducts.batteries,
          productState: project.components.battery,
          onPreferenceChange: (filters) => {
            updateBatteryPref(filters);
          },
        },
      };
      dispatch(PopupActions.updateData(POPUP_KEYS.product, data));
    }

    if (lastOpenedPopup === "wallbox") {
      const data: ProductPopupProps = {
        type: "wallbox",
        standalone: {
          componentCosts: project.financialDetails.costs.componentCosts,
          filteredProducts: filteredProducts.wallboxes,
          productState: project.components.wallbox,
          onPreferenceChange: (filters) => {
            updateWallboxPref(filters);
          },
        },
      };
      dispatch(PopupActions.updateData(POPUP_KEYS.product, data));
    }

    if (lastOpenedPopup === "heatpump") {
      const data: ProductPopupProps = {
        type: "heatpump",
        standalone: {
          componentCosts: project.financialDetails.costs.componentCosts,
          filteredProducts: filteredProducts.heatpumps,
          productState: project.components.heatpump,
          onPreferenceChange: (filters) => {
            updateHeatpumpPref(filters);
          },
        },
      };
      dispatch(PopupActions.updateData(POPUP_KEYS.product, data));
    }

    if (lastOpenedPopup === "inverter") {
      const data: ProductPopupProps = {
        type: "inverter",
        standalone: {
          componentCosts: project.financialDetails.costs.componentCosts,
          filteredProducts: filteredProducts.inverters,
          productState: project.components.inverter,
          onPreferenceChange: (filters) => {
            updateInverterPref(filters);
          },
        },
      };
      dispatch(PopupActions.updateData(POPUP_KEYS.product, data));
    }
  }, [
    dispatch,
    filteredProducts.batteries,
    filteredProducts.heatpumps,
    filteredProducts.inverters,
    filteredProducts.solarPanels,
    filteredProducts.wallboxes,
    lastOpenedPopup,
    project.components.battery,
    project.components.heatpump,
    project.components.inverter,
    project.components.solarPanel,
    project.components.wallbox,
    project.financialDetails.costs.componentCosts,
    updateBatteryPref,
    updateHeatpumpPref,
    updateInverterPref,
    updateSolarPanelPref,
    updateWallboxPref,
  ]);

  const imgURLs: any = useMemo(() => {
    const greenImgURL = project?.quote?.profiles?.green?.imgURLs?.find((img: string) =>
      img?.includes(".obj"),
    );
    const artifactImgURL = Array.isArray(project?.bakeModel?.artifacts)
      ? project?.bakeModel?.artifacts.find((img: string) => img?.includes(".obj"))
      : undefined;

    return greenImgURL || artifactImgURL;
  }, [project?.quote?.profiles?.green?.imgURLs, project?.bakeModel?.artifacts]);

  const threeDmodelhandler = () => {
    dispatch(openPopup(POPUP_KEYS.threedmodel,{isfromSavedProject:true}));
    dispatch(
      AppActions.updateAppState({
        actions: {
          shouldLoadUniqueProjects: true,
        },
      }),
    );
    if (!installerPartnerSlug) {
      navigate(`/application/saved-${projectDB.shortId}/${projectDB.version}`);
    } else {
      navigate(
        `/application/saved-${projectDB.shortId}/${projectDB.version}?partner=${installerPartnerSlug}`,
      );
    }
  };
  const isRoleValid = rolechecker(user?.role)

  return (
    <MainContainer>
      <div className="nake_ite">
        <div className="card_main">
          <div className="header_">
            {savedProjectsEnabled ? (
              <div className={"text textSmall"}>
                <Tooltip placement="top" title={project.quote.mapboxAddress?.complete}>
                  {project.quote.mapboxAddress?.complete}
                </Tooltip>
              </div>
            ) : (
              <div className="text">
                {t("Offer")} {projectDB.profile} {projectDB.version}
              </div>
            )}
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <div
                className={`center ${window.innerWidth > 500 ? "buttonShare" : "newBtt"}`}
                onMouseEnter={() => {
                  const timer: any = () =>
                    setTimeout(() => {
                      setShareBtnHover(true);
                    }, 200);
                  const timerId: any = timer();
                  setTrackId(timerId);
                }}
                onMouseLeave={() => {
                  if (!shareBtnHover) {
                    clearTimeout(trackId);
                  }
                  setShareBtnHover(false);
                }}
                onClick={() => {
                  if (window.innerWidth < 500) {
                    setOpenShareTray(true);
                  }
                }}
              >
                <ShareIcon />
                {shareBtnHover && window.innerWidth > 500 && <MixButton />}
              </div> */}
              <CloseIcon
                onClick={() => onDelete(project.quote.mapboxAddress.complete, projectDB._id)}
                style={{ zIndex: "1", marginRight: "10px", cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="borders"></div>
          {!savedProjectsEnabled && (
            <div className="next_text">
              <Tooltip placement="top" title={project?.quote?.mapboxAddress?.complete}>
                {project?.quote?.mapboxAddress?.complete}
              </Tooltip>
            </div>
          )}
          {!savedProjectsEnabled && <div className="borders2"></div>}
          <div className="continaer">
            <div className="same_part">
              <div className="heading">{t("Energy production")}</div>
              <div className="info">
                {isRoleValid && (
                  <div className="first_thing">
                    <div className="again_same">
                      <p style={{ fontWeight: "bold" }}>{t("User Name")}</p>
                    </div>
                    <div className="poew">{`${project?.userDetails?.firstName} ${project?.userDetails?.lastName}`}</div>
                  </div>
                )}
                <div className="first_thing">
                  <div className="again_same">
                    <img src={first} alt="" />
                    <p>{t("Amount of panels")}</p>
                  </div>
                  <div className="poew">{project.components.solarPanel.quantity}</div>
                </div>
                <div className="first_thing">
                  <div className="again_same" onClick={openSolarPanelPopup}>
                    <img src={secound} alt="" />
                    <p>{t("Panel capacity")}</p>
                  </div>
                  <div className="poew" onClick={openSolarPanelPopup}>
                    {formatNumber(panelCapacity) + " " + "kwp"}
                  </div>
                </div>
                <div className="first_thing">
                  <div className="again_same1">
                    <img src={third} alt="" />
                    <p>{t("System capacity")}</p>
                  </div>
                  <div className="poew">
                    {formatNumber(systemCapacity)}
                    kWp
                  </div>
                </div>
                <div className="first_thing">
                  <div className="again_same" onClick={openInverterPopup}>
                    <img src={first} alt="" />
                    <p>{t("inverter")}</p>
                  </div>
                  <div className="poew" onClick={openInverterPopup}>
                    {formatNumber(
                      getNumber(project.components.inverter.preferences.applicableMaxCapacity) /
                        1000,
                    )}{" "}
                    Kw
                  </div>
                </div>
              </div>
            </div>
            <div className="borders1"></div>
            <div className="same_part">
              <div className="heading">{t("Energy storage & consumption")}</div>
              <div className="info">
                <div className="first_thing">
                  {project.components.battery.quantity ? (
                    <>
                      <div className="again_same" onClick={() => openBatteryPopup()}>
                        <img src={four} alt="" />
                        <p>{t("Battery")}</p>
                      </div>
                      <div className="delete_product">
                        <div
                          className="poew"
                          onClick={() => {
                            openBatteryPopup();
                          }}
                        >
                          {formatNumber(project.financialDetails.costs.componentCosts.batteryCost)}€
                        </div>
                        <img
                          src={deletess}
                          onClick={() => deleteBattery()}
                          style={{ cursor: "pointer" }}
                          alt=""
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      className="again_same"
                      onClick={() => {
                        addBattery();
                        openBatteryPopup();
                      }}
                    >
                      <img src={four} alt="" />
                      <p>{t("ADD BATTERY")}</p>
                    </div>
                  )}
                </div>
                <div className="first_thing">
                  {project.components.wallbox.quantity ? (
                    <>
                      <div className="again_same" onClick={() => openWallboxPopup()}>
                        <img src={five} alt="" />
                        <p>{t("Wallbox")}</p>
                      </div>
                      <div className="delete_product">
                        <div className="poew" onClick={() => openWallboxPopup()}>
                          {formatNumber(project.financialDetails.costs.componentCosts.wallboxCost)}€
                        </div>
                        <img
                          src={deletess}
                          onClick={() => deleteWallbox()}
                          style={{ cursor: "pointer" }}
                          alt=""
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      className="again_same"
                      onClick={() => {
                        addWallbox();
                        openWallboxPopup();
                      }}
                    >
                      <img src={five} alt="" />
                      <p>{t("ADD WALLBOX")}</p>
                    </div>
                  )}
                </div>
                {installerDetails?.isAllowHitPump && (
                  <div className="first_thing">
                    {project.components.heatpump.quantity &&
                    !project.components.heatpump.isExcludeCost ? (
                      <>
                        <div className="again_same" onClick={() => openHeatpumpPopup()}>
                          <img src={six} alt="" />
                          <p>{t("heat pump")}</p>
                        </div>
                        <div className="delete_product">
                          <div className="poew" onClick={() => openHeatpumpPopup()}>
                            {formatNumber(
                              project.financialDetails.costs.componentCosts.heatpumpCost,
                            )}
                            €
                          </div>
                          <img
                            src={deletess}
                            onClick={() => deleteHeatpump()}
                            style={{ cursor: "pointer" }}
                            alt=""
                          />
                        </div>
                      </>
                    ) : (
                      <div
                        className="again_same"
                        onClick={() => {
                          addHeatpump();
                          openHeatpumpPopup();
                        }}
                      >
                        <img src={six} alt="" />
                        <p>{t("ADD HEATPUMP")}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="borders1"></div>
            <div className="inside_container">
              <div className="new_same">
                <div className="text_same">{t("Price total project")}</div>
                <div className="text_same">
                  {formatNumber(Math.floor(project.financialDetails.costs.appliedProjectCost))} €
                </div>
              </div>
              <div className="new_same">
                <div className="littel_diff">{t("Amortisation time")}</div>
                <div className="littel_diff">
                  {project.financialDetails.amortizationTime} {t("YEARS")}
                </div>
              </div>
              {project.financialDetails.isFinancing && (
                <div className="new_same">
                  <div className="littel_diff">{t("FINANCE TIME")}</div>
                  <div className="littel_diff">12 {t("YEARS")}</div>
                </div>
              )}

              <div className="new_same">
                <div className="text_same">{t("ROI after 20 Years")}</div>
                <div className="text_same">{formatNumber(Math.floor(after20Years))} €</div>
              </div>
            </div>
            {savedProject && (
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  gap: "4px",
                  flexDirection: "column",
                }}
              >
                <div className="flex gap-1">
                  <Button
                    className={`myButton ${!t("myButtonGerman")}`}
                    onClick={() => {
                      selectButtonHandler();
                    }}
                  >
                    {t("Select offer")}
                  </Button>
                  {(imgURLs || project.googleModel) && (
                    <Button
                      className={`myButton ${!t("myButtonGerman")}`}
                      onClick={() => {
                        threeDmodelhandler();
                      }}
                    >
                      {t("View 3D model")}
                    </Button>
                  )}
                </div>
                {user?.iid && (user.role == "ADMIN" || user.role == "INSTALLER") && (
                  <Button
                    className={`myButton ${!t("myButtonGerman")}`}
                    onClick={() =>
                      dispatch(
                        openPopup(POPUP_KEYS.realisationtime, {
                          pid: projectDB._id,
                          realizationWeek: projectDB?.selectedInstallers?.find((installer) => installer?.iid === user?.iid),
                          setfilteredData,
                        }),
                      )
                    }
                  >
                    {t("Add realization time")}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </MainContainer>
  );
};
