import React from "react";
import { ClipLoader } from "react-spinners";

const ConfirmationDialog = ({ isOpen, onClose, onConfirm, onCancel, text, confirmText, cancelText, loader = false, disableConfirm = false, disableCancel = false }) => {

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
        {loader && (
          <ClipLoader size={40} />
        )}
        <h3 className="text-lg font-semibold">{text}</h3>
        {!loader && (
          <div className="mt-4 flex justify-center gap-4">
            {!disableConfirm && (
              <button
                onClick={() => {
                  onConfirm();
                  onClose();
                }}
                className="bg-primary-color text-darkest-text px-4 py-2 rounded-lg hover:opacity-button-hover hover:scale-button-hover"
              >
                {confirmText}
              </button>
            )}
            {!disableCancel && (
              <button
                onClick={() => {
                  onCancel();
                  onClose();
                }}
                className="bg-grey text-darkest-text px-4 py-2 rounded-lg hover:opacity-button-hover hover:scale-button-hover"
              >
                {cancelText}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmationDialog;
