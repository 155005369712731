import styled from "styled-components/macro";

export const MainText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 22px;
  color: #304864;
  display: flex;
  gap: 5px;
`;

export const UnderlinedButton = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 12px; /* Adjust size as needed */
  color: grey; /* Much lighter shade of #304864 */
  text-decoration: underline; /* Underlines the text */
  cursor: pointer; /* Makes it clickable */
  &:hover {
    text-decoration: none; /* Removes underline on hover */
    color: grey; /* Slightly darker hover color */
  }
`;