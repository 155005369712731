import { googleMapsReverseGeoResponse } from "src/api";

import { ApiAxiosClient } from "src/axios";

import { MapboxAddress } from "src/redux/project";
// import { googleMapsReverseGeoQuery } from "src/api/third-party-endpoints";

export const parseReverseGeoGoogle = async (placeId: string): Promise<MapboxAddress | undefined> => {
  try {
    console.log(placeId);
    
    const response = await ApiAxiosClient.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/quote/place/${placeId}`,
    );
 
    const data: googleMapsReverseGeoResponse = response?.data?.data;
    const addressComponents = data.result.address_components;

    let locality = "";
    let city = "";
    let state = "";
    let country = "";
    let postcode = "";
    let number = "";

    addressComponents.forEach((component) => {
      if (component.types.includes("street_number")) {
        number = component.long_name;
      } else if (component.types.includes("route")) {
        locality = component.long_name;
      } else if (component.types.includes("locality")) {
        city = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (component.types.includes("country")) {
        country = component.long_name;
      } else if (component.types.includes("postal_code")) {
        postcode = component.long_name;
      }
    });

    return {
      address: data.result.formatted_address,
      number,
      locality,
      city,
      state,
      postcode,
      country,
      complete: data.result.formatted_address,
      geometry: {
        type: "Point",
        coordinates: [data.result.geometry.location.lng, data.result.geometry.location.lat],
      },
    };
  } catch (error) {
    console.error("parseReverGeo() ", error);
  }
};
