import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CalendarIcon, LocationStep4Icon } from "src/assets/svgs";
import { InstallerDB } from "src/db-types";
import { CloseButton } from "src/shared/components";

import * as S from "./InstallerCard.styles";

import { PopupSelectors, ProjectSelectors } from "src/redux/selectors";
import { useAppDispatch } from "src/hooks";
import { PopupActions } from "src/redux";

interface InstallerCardProps {
  data: InstallerDB;
  isAlreadyRequested: boolean;
  noNeedToFitHeight?: boolean;
  onRequestBtnClick: (installer: InstallerDB) => void;
  onAcceptBtnClick: (installer: InstallerDB) => void;
  onCancelBtnClick: (installer: InstallerDB) => void;
}
const InstallerCard: React.FC<InstallerCardProps> = (props: InstallerCardProps) => {
  const {
    data: installerData,
    onCancelBtnClick,
    onRequestBtnClick,
    onAcceptBtnClick,
    noNeedToFitHeight,
    isAlreadyRequested,
  } = props;

  const { companyName, address, distanceInKm, logoUrl: installerImageURLs } = installerData;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dataPolicyPopupAccept = useSelector(PopupSelectors.getDataPolicyPopupAccept);

  const [logoURL, setLogoURL] = useState("");

  useEffect(() => {
    if (installerImageURLs?.length > 0) {
      const media = installerImageURLs[0];
      setLogoURL(media);
    }
  }, [installerImageURLs]);

  const [isMatchedOffer] = useState<any[]>([]);

  const houseTopImages = useSelector(ProjectSelectors.getHouseTop).imageURLs;
  const houseBottomImages = useSelector(ProjectSelectors.getHouseBottom).imageURLs;

  const generateDistanceString = (distance: number): string => {
    if (isNaN(distance)) return "";
    return `, ${Math.round(distance)}${t("km away from your roof")}`;
  };

  const [requestRealizationTime, setRequestRealizationTime] = useState(false);
  const handleRequestRealizationTime = useCallback(() => {
    if (isAlreadyRequested) return;
    setRequestRealizationTime(true);
    dispatch(PopupActions.openDataPolicyPopup());
  }, [isAlreadyRequested]);

  useEffect(() => {
    if (
      dataPolicyPopupAccept.open === false &&
      dataPolicyPopupAccept.accept &&
      requestRealizationTime
    ) {
      setRequestRealizationTime(false);
      dispatch(PopupActions.closeDataPolicyPopup());
      onRequestBtnClick(installerData);
    } else if (
      dataPolicyPopupAccept.open === false &&
      !dataPolicyPopupAccept.accept &&
      requestRealizationTime
    ) {
      setRequestRealizationTime(false);
    }
  }, [dataPolicyPopupAccept]);

  // const haveImages = houseTopImages.length > 0 || houseBottomImages.length > 0;
  const haveImages = true; // user can request without images

  const notHaveImages = !haveImages;

  // TODO : got offer process
  const haveGotOffer = isMatchedOffer?.length > 0 && isMatchedOffer[0]?.realization_week_short;

  return (
    <S.Card noNeedToAlignHeight={noNeedToFitHeight} isPad={notHaveImages}>
      <S.CardHeader>
        <img src={logoURL} className="logo_url" alt="" />{" "}
        <span onClick={() => onCancelBtnClick(installerData)}>
          <CloseButton onClick={() => {}} variant="only-cross" />
        </span>
      </S.CardHeader>
      <S.CardDetails>
        <h3>
          {companyName.slice(0, 40)}
          {companyName.length > 40 && "..."}
        </h3>
        <S.Location>
          <LocationStep4Icon />
          <span>
            {address}
            {generateDistanceString(distanceInKm)}
          </span>
        </S.Location>
        <div style={{ flex: 1 }}></div>
        {haveGotOffer && (
          <>
            <S.Date>
              <CalendarIcon />
              <span>{isMatchedOffer[0].realization_week_short}</span>
            </S.Date>
            <button onClick={() => onAcceptBtnClick(installerData)}>Accept offer</button>
          </>
        )}
        {haveImages && (
          <button onClick={handleRequestRealizationTime}>
            {isAlreadyRequested ? t("waiting for confirmation") : t("Request realization time")}
          </button>
        )}
      </S.CardDetails>
    </S.Card>
  );
};

export default InstallerCard;
