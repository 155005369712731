import { Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { InfoIcon } from "src/shared/components";

import * as S from "./CardHeader.styles";

import { ProjectImagesKey } from "src/redux/project/types/house-images";

interface CardHeaderProps {
  cardType: ProjectImagesKey;
  setIsDialogOpen?:any;
}
export const CardHeader: React.FC<CardHeaderProps> = (props: CardHeaderProps) => {
  const { cardType, setIsDialogOpen } = props;

  const { t } = useTranslation();

  const houseRoofText = "House/roof area details";
  const houseBottomText = "Roof from below details";
  const electricMeterText = "Electric meter details";
  const droneDataText = "Drone Data Upload";

  return (
    <div>
      <Tooltip placement="topRight" title={t("please upload pictures separately")}>
        <S.MainText style={cardType === "ThreeDModels" ? { textTransform: "unset" } : {}}>
          {cardType === "houseTop" && t(houseRoofText)}
          {cardType === "houseBottom" && t(houseBottomText)}
          {cardType === "electricMeter" && t(electricMeterText)}
          {cardType === "ThreeDModels" && t(droneDataText)}
          <InfoIcon />
        </S.MainText>
      </Tooltip>
      {cardType === "ThreeDModels" && (
        <S.UnderlinedButton onClick={() => setIsDialogOpen(true)}>
          {t("NO_DRONE_PICTURES")}
        </S.UnderlinedButton>
      )}
    </div>
  );
};
