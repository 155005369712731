import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { InstallerDB } from "src/db-types";
import { PopupSkeleton } from "src/shared/components";

import { MainContainer } from "./HubSpotBookCall.styles";

import { POPUP_KEYS } from "src/redux/popups";
import { closePopup } from "src/redux/popups/action/action.creators";
import { getCompleteAddress } from "src/redux/project/selectors";
import { UserSelectors } from "src/redux/selectors";
import { RootState } from "src/redux/store";
import { getUser } from "src/redux/user/selectors";

export const HubSpotBookCall: React.FC = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const open = useSelector((state: RootState) => state.popup.HUBSPOT_MEETING.open);
  const installer: InstallerDB | null = useSelector(UserSelectors.getInstaller);
  const user = useSelector(getUser);
  const firstName = user?.firstName;
  const lastName = user?.lastName;
  const selectedAddress = useSelector(getCompleteAddress);

  useEffect(() => {
    const script: any = document.createElement("script");
    script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.onload = function (ev: any) {
      console.log("Hubspot script loaded");
    };
    script.onerror = function (ev: any) {
      console.error("Hubspot script load error", ev);
    };
    document.body.appendChild(script);
  }, [open]);

  const meetingsUrl = `${
    installer?.appointmentURL &&
    installer?.appointmentURL !== "" &&
    installer?.whichOptionSelected === "goToHubspot"
      ? installer?.appointmentURL
      : "https://meetings.hubspot.com/daniel-watz/experte?firstname"
  }=${encodeURIComponent(firstName ?? "")}&email=${user?.email ?? ""}&lastname=${encodeURIComponent(
    lastName ?? "",
  )}&Bitte gib vorab deine Adresse an, damit wir uns dein Dach ansehen können.=${
    selectedAddress?.replace("Germany", "Deutschland") || ""
  }&embed=true`;

  return (
    <PopupSkeleton
      styless={{ paddingTop: "0px" }}
      handleClose={() => {
        dispatch(closePopup(POPUP_KEYS.hubspot_meeting));
      }}
      open={open}
    >
      <MainContainer>
        <div className="meetings-iframe-container" data-src={meetingsUrl} ref={ref.current}></div>
      </MainContainer>
    </PopupSkeleton>
  );
};
