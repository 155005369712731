import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { LocationStep4Icon } from "src/assets/svgs";
import { CloseButton } from "src/shared/components";

import * as S from "./FreeChoice.styles";

import { PopupSelectors, ProjectSelectors } from "src/redux/selectors";
import { useCallback, useEffect, useState } from "react";
import { PopupActions } from "src/redux";

interface FreeChoiceProps {
  onRequestBtnClick: () => void;
}
export const FreeChoice: React.FC<FreeChoiceProps> = (props: FreeChoiceProps) => {
  const { onRequestBtnClick } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const dataPolicyPopupAccept = useSelector(PopupSelectors.getDataPolicyPopupAccept);

  const houseTopImages = useSelector(ProjectSelectors.getHouseTop).imageURLs;
  const houseBottomImages = useSelector(ProjectSelectors.getHouseBottom).imageURLs;
  const isFreeInstallerRequested = useSelector(ProjectSelectors.getVendor).freeChoiceInstaller;

  // const hasImages = !!houseBottomImages.length || !!houseTopImages.length;
  const hasImages = true; // user can request without images


  const [requestRealizationTime, setRequestRealizationTime] = useState(false);
  const handleRequestRealizationTime = useCallback(() => {
    if (isFreeInstallerRequested) return;
    setRequestRealizationTime(true);
    dispatch(PopupActions.openDataPolicyPopup());
  }, [isFreeInstallerRequested]);

  useEffect(() => {
    if (
      dataPolicyPopupAccept.open === false &&
      dataPolicyPopupAccept.accept &&
      requestRealizationTime
    ) {
      setRequestRealizationTime(false);
      dispatch(PopupActions.closeDataPolicyPopup());
      onRequestBtnClick();
    } else if (
      dataPolicyPopupAccept.open === false &&
      !dataPolicyPopupAccept.accept &&
      requestRealizationTime
    ) {
      setRequestRealizationTime(false);
    }
  }, [dataPolicyPopupAccept]);

  return (
    <S.InstallerOurChoice isPad={!hasImages}>
      <S.CardHeader>
        {t("Free installer choice")}{" "}
        <span>
          <CloseButton variant="only-cross" onClick={() => {}} />
        </span>
      </S.CardHeader>
      <S.CardDetails>
        <p className="free-choice-text">{t("FREECHOICE TEXT")}</p>
        <div className="location">
          <LocationStep4Icon />
          <span>{t("Closest available installer")}</span>
        </div>

        <div style={{ flex: 1 }}></div>
        {hasImages && (
          <button onClick={handleRequestRealizationTime}>
            {isFreeInstallerRequested
              ? t("waiting for confirmation")
              : t("Request realization time")}
          </button>
        )}
      </S.CardDetails>
    </S.InstallerOurChoice>
  );
};
