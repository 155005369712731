import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { BackendApis } from "src/api";

import { useAppSelector } from "src/hooks";

import { updateQuote } from "src/api/backend-endpoints";
import { ApiAxiosClient } from "src/axios";
import { useSaveMedia } from "src/hooks/apis";

import { CardHeader, SampleImages, UploadFileCard } from "./components";
import Description from "./Description";
import * as S from "./styles";
import { UploadedImageView } from "./UploadImage";

import { AppActions, ProjectActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { openPopup } from "src/redux/popups/action/action.creators";
import { ProjectImagesKey } from "src/redux/project/types/house-images";
import { ProjectSelectors } from "src/redux/selectors";

const getSelectorFn = (type: ProjectImagesKey) => {
  if (type === "electricMeter") return ProjectSelectors.getElectricMeter;
  else if (type === "houseTop") return ProjectSelectors.getHouseTop;
  else if (type === "ThreeDModels") return ProjectSelectors.getDroneData;
  else return ProjectSelectors.getHouseBottom;
};

type HouseTopAction = typeof ProjectActions.updateHouseTop;
type HouseBottomAction = typeof ProjectActions.updateHouseBottom;
type ElectricMeterAction = typeof ProjectActions.updateElectricMeter;
type ThreeDModelsAction = typeof ProjectActions.updateDroneData;
type ImagesUpdateAction =
  | HouseBottomAction
  | HouseTopAction
  | ElectricMeterAction
  | ThreeDModelsAction;

interface UploadCardProps {
  cardType: ProjectImagesKey;
  setPercentage: any;
  selectedFiles: any;
  isUploadPaused?: boolean;
  setIsDialogOpen?: any;
}
export const UploadCard: React.FC<UploadCardProps> = (props: UploadCardProps) => {
  const { cardType, isUploadPaused, setIsDialogOpen } = props;
  const [cancelTokenSource, setCancelTokenSource] = useState<any>(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const houseImageDetails = useAppSelector(getSelectorFn(cardType));
  const { error, fetchData, loading, response } = useSaveMedia();
  const project = useAppSelector(ProjectSelectors.getProject);

  const getDispatchAction = useCallback((cardType: ProjectImagesKey): ImagesUpdateAction => {
    let action = ProjectActions.updateHouseTop;
    if (cardType === "houseBottom") {
      action = ProjectActions.updateHouseBottom;
    } else if (cardType === "electricMeter") {
      action = ProjectActions.updateElectricMeter;
    } else if (cardType === "ThreeDModels") {
      action = ProjectActions.updateDroneData;
    }
    return action;
  }, []);

  useEffect(() => {
    if (!error) return;
    console.error(error);
    toast.error(t("There was a problem uploading images"));
  }, [error, t]);

  useEffect(() => {
    if (!response) return;

    const {
      data: { data: uploadedMedias },
    } = response;

    const action = getDispatchAction(cardType);
    const uploadedMediasURLs: any = uploadedMedias.map((media) => media);

    dispatch(
      action({
        imageURLs: [...houseImageDetails.imageURLs, ...uploadedMediasURLs],
      }),
    );
    props.setPercentage((prevProgress: any) => {
      return { ...prevProgress, [uploadedMediasURLs?.[0]?.fileName]: 100 };
    });

    dispatch(
      AppActions.updateAppState({
        actions: {
          updateDeal: true,
        },
      }),
    );
  }, [cardType, dispatch, getDispatchAction, houseImageDetails.imageURLs, props, response]);

  async function checkDroneImage(files: File[]): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      for (const file of files) {
        const reader = new FileReader();

        reader.onload = (e) => {
          if (!e.target || !(e.target.result instanceof ArrayBuffer)) {
            reject(new Error("File reading error"));
            return;
          }
          const data = new DataView(e.target.result);
          let offset = 2;
          const marker = (data.getUint8(offset) << 8) | data.getUint8(offset + 1);
          offset += 2;

          if (marker !== 0xffe1) {
            // Not an EXIF marker
            resolve(false);
            return;
          }

          const exifLength = data.getUint16(offset);
          offset += 2;

          const exifData = new TextDecoder().decode(new DataView(data.buffer, offset, exifLength));

          // Check for "DJI" or other drone manufacturers in the EXIF data
          if (exifData.includes("DJI")) {
            resolve(true); // It's a drone image
          } else {
            resolve(false); // Not a drone image
          }
        };

        reader.onerror = (e) => {
          reject(e);
        };

        reader.readAsArrayBuffer(file);
      }
    });
  }

  const fileUploadHandler = useCallback(
    async (files: File[]): Promise<void> => {
      const allFiles = [...props.selectedFiles, ...files];
      if (cardType === "ThreeDModels") {
        dispatch(ProjectActions.addSelectedFiles(allFiles));

        if (allFiles.length) {
          await ApiAxiosClient.put(updateQuote.url, {
            status: "VERIFIED",
            id: project.quote.id,
          });

          dispatch(
            ProjectActions.updateProject({
              ...project,
              quote: {
                ...project.quote,
                status: "VERIFIED",
              },
            }),
          );
        }
      }
      const source = axios.CancelToken.source();
      setCancelTokenSource(source);

      const uploadNextBatch = async (startIndex: number): Promise<void> => {
        const endIndex = Math.min(startIndex + 3, files.length);
        const currentBatch = files.slice(startIndex, endIndex);

        const uploadPromises = currentBatch.map(async (file) => {
          const formData = new FormData();
          formData.append("type", "savedProject");
          formData.append("file_upload", file);

          try {
            await fetchData({
              ...BackendApis.uploadImages,
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data;",
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100,
                );

                props.setPercentage((prevProgress: any) => {
                  return { ...prevProgress, [file.name]: percentCompleted - 1 };
                });
              },
              cancelToken: source.token,
            });
          } catch (error) {
            console.log(error);
          }
        });

        await Promise.all(uploadPromises);

        const nextBatchStartIndex = startIndex + 3;

        if (nextBatchStartIndex < files.length) {
          await uploadNextBatch(nextBatchStartIndex);
        }
      };

      let isDroneImage = await checkDroneImage(files);

      if (cardType !== "ThreeDModels") isDroneImage = true;
      if (!isDroneImage) {
        toast.error("Only drone images are supported for 3D models.");
        return;
      }

      await uploadNextBatch(0);
      if (cardType === "ThreeDModels") {
        dispatch(openPopup(POPUP_KEYS.request_pv_model));
      }
    },
    [props, cardType, dispatch, project, fetchData],
  );

  const fileDeleteHandler = useCallback(
    (deleteURL: string) => {
      const action = getDispatchAction(cardType);
      dispatch(
        action({
          imageURLs: houseImageDetails.imageURLs.filter((url) => url !== deleteURL),
        }),
      );

      dispatch(
        AppActions.updateAppState({
          actions: {
            updateDeal: true,
          },
        }),
      );
    },
    [cardType, dispatch, getDispatchAction, houseImageDetails.imageURLs],
  );

  useEffect(() => {
    if (isUploadPaused) {
      cancelTokenSource.cancel("Component unmounted.");
    }
  }, [isUploadPaused]);

  const showSampleImages = !loading && houseImageDetails.imageURLs.length === 0;
  const showRealImages = !loading && houseImageDetails.imageURLs.length > 0;
  return (
    <S.MainContainer>
      <CardHeader cardType={cardType} setIsDialogOpen={setIsDialogOpen}/>
      <UploadFileCard onFileAdd={fileUploadHandler} />
      {loading && (
        <S.LoadContainer>
          <CircularProgress />
        </S.LoadContainer>
      )}
      {showSampleImages && <SampleImages imagesType={cardType} />}
      {showRealImages && (
        <UploadedImageView
          imageURLs={houseImageDetails.imageURLs}
          removeMediaHandler={fileDeleteHandler}
        />
      )}
      <Description cardType={cardType} />
    </S.MainContainer>
  );
};
